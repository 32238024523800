export default class Header {
  constructor(element) {
    this.element = element;
    this.currentPage = element.dataset.page;
    this.maxPages = element.dataset.maxPages;
    this.loadMoreButton = element.querySelector(".o-teaser__load-more");

    if (this.loadMoreButton) {
      this.loadMoreButton.addEventListener("click", () => this.loadMorePosts());
    }
  }

  loadMorePosts() {
    this.removeButton();
  }

  removeButton() {
    this.element.removeChild(this.loadMoreButton);
  }
}
