import SingleProductGallery from './single-product';

const singleProductGallery = document.querySelectorAll(
  '[data-js-module="t-single-product__gallery"]'
);

const init = () => {
  singleProductGallery.forEach(element => new SingleProductGallery(element));
};

if (singleProductGallery.length) init();
