export default class Video {
  constructor(element) {
    this.poster = element.querySelector('.m-youtube-video__poster');
    this.play = element.querySelector('.m-youtube-video__play');
    this.frame = element.querySelector('.m-youtube-video__frame');
    this.videoId = element.dataset.videoId;

    this.bindEventListener();
  }

  handlePlay() {
    this.frame.innerHTML = `<iframe class="m-youtube-video__iframe" src="https://www.youtube-nocookie.com/embed/${this.videoId}?rel=0&autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen/>`;
    this.poster.classList.add('is-playing');
    this.play.classList.add('is-playing');
  }

  bindEventListener() {
    this.play.addEventListener('click', () => this.handlePlay());
  }
}
