export default class PartnerList {
  constructor(element) {
    this.entries = element.querySelectorAll('.o-partner-list__entry');
    this.panels = element.querySelectorAll('.o-partner-list__panel');
    this.visible = 'is-visible';

    this.bindEventListeners();
  }

  bindEventListeners() {
    this.entries.forEach((entry, index) => {
      const toggles = entry.querySelectorAll('.o-partner-list__entry-toggle');
      toggles[0].addEventListener('click', () => this.handleToggle(index));
    });
  }

  handleToggle(i) {
    this.entries.forEach((entry, index) => {
      if (index === i) {
        if (entry.classList.contains(this.visible)) {
          entry.classList.remove(this.visible);
          this.panels[index].setAttribute('aria-visible', 'false');
        } else {
          entry.classList.add(this.visible);
          this.panels[index].setAttribute('aria-visible', 'true');
        }
      } else {
        if (entry.classList.contains(this.visible)) {
          entry.classList.remove(this.visible);
          this.panels[index].setAttribute('aria-visible', 'false');
        }
      }
    });
  }
}
