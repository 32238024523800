export default class Header {
  constructor(element) {
    this.handleScroll(element);

    this.navigation = element.querySelector('.o-header__navigation');
    this.triggers = element.querySelectorAll('.o-header__trigger');

    this.triggers.forEach(trigger => {
      trigger.addEventListener('click', () => this.toggleNavigation());
    });

    window.addEventListener('scroll', () => this.handleScroll(element));
  }

  toggleNavigation() {
    const isOpen = this.navigation.getAttribute('aria-visible');

    if (isOpen === 'false') {
      this.navigation.setAttribute('aria-visible', 'true');
    } else {
      this.navigation.setAttribute('aria-visible', 'false');
    }
  }

  handleScroll(element) {
    if (window.scrollY > 500) {
      element.classList.add('o-header--scrolled');
    } else {
      element.classList.remove('o-header--scrolled');
    }
  }
}
