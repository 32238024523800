export default class SingleProductGallery {
  constructor(element) {
    this.dots = element.querySelectorAll('.t-single-product__gallery-dot');
    this.images = element.querySelectorAll('.t-single-product__gallery-image');
    this.index = 0;

    this.interval = this.autoplay();

    this.dots.forEach((dot, index) => {
      dot.addEventListener('click', () => {
        this.changeIndex(index);
      });
    });

    element.addEventListener('mouseenter', () => {
      clearInterval(this.interval);
    });

    element.addEventListener('mouseleave', () => {
      this.interval = this.autoplay();
    });
  }

  autoplay() {
    return setInterval(() => {
      if (this.index + 1 >= this.images.length) {
        this.changeIndex(0);
      } else {
        this.changeIndex(this.index + 1);
      }
    }, 8000);
  }

  changeIndex(newIndex) {
    if (this.index === newIndex) return;

    this.index = newIndex;

    this.images.forEach((image, index) => {
      if (image.classList.contains('is-visible') && index !== newIndex) {
        image.classList.remove('is-visible');
      }

      if (index === newIndex) {
        image.classList.add('is-visible');
      }
    });

    this.dots.forEach((dots, index) => {
      if (dots.classList.contains('is-visible') && index !== newIndex) {
        dots.classList.remove('is-visible');
      }

      if (index === newIndex) {
        dots.classList.add('is-visible');
      }
    });
  }
}
